import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import Arrow from '../assets/arrows.svg';
import Close from '../assets/xmark-solid.svg';

const ChatBubble: React.FC = () => {
  const [isChatBubbleVisible, seIsChatBubbleVisible] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);  
  const [isWelcomeBubbleVisible, setIsWelcomeBubbleVisible] = useState(true);  

  const toggleChatBubble = () => {
    seIsChatBubbleVisible(!isChatBubbleVisible);
    setIsWelcomeBubbleVisible(!isWelcomeBubbleVisible);
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        className="toggle-button fixed bottom-5 right-5 w-14 h-14 border-none rounded-full bg-transparent cursor-pointer z-50"
        onClick={toggleChatBubble}
        aria-label="Toggle chat widget"
      >
        <Image
          src="https://storage.googleapis.com/cdao_bot_images/orbgif.gif"
          unoptimized
          alt="Chat"
          width={30}
          height={30}
          className="w-full h-auto rounded-full"
        />
      </button>

      <div className={
        `welcome-bubble fixed bottom-20 right-20 bg-[#333333] text-white p-2.5 rounded-[25px] rounded-br-[1px] shadow-md flex items-center gap-2.5 z-[1002] transition-all duration-300 ease-in-out
        ${isWelcomeBubbleVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-0 pointer-events-none'}        
        `}
      >
        <p>Hi! I'm the Tradewinds AI ChatBot. How can I help?</p>
      </div>

      <div
      className={`
        floating-widget fixed bg-gray-800 rounded-lg shadow-lg z-50 flex flex-col transition-all duration-300 ease-in-out
        ${isMaximized ? 'inset-0 m-auto' : 'bottom-20 right-20'} 
        ${isMaximized ? 'w-[90vw] h-[90vh]' : 'w-1/2 h-3/4'}
        ${isChatBubbleVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-0 pointer-events-none'}
      `}
    >
      <div className="title-bar p-5 flex justify-between items-center max-h-24 bg-black rounded-t-lg overflow-hidden flex-row">
        <Image
          src="https://storage.googleapis.com/cdao_bot_images/tw-chat-bot-logo.svg"
          alt="Primary Logo"
          className="title-logo w-3/4 max-w-lg object-contain"
          width={200}
          height={50}
        />
        <div className="button-group flex items-baseline">
          <button className="minimize-button bg-transparent border-none text-white cursor-pointer w-3.5 h-3.5 mr-3" onClick={toggleMaximize}>
            <Image src={Arrow} alt="Minimize" width={25} height={25} />
          </button>
          <button className="maximize-button bg-transparent border-none text-white cursor-pointer w-4 h-4" onClick={toggleChatBubble}>
            <Image src={Close} alt="Close" width={25} height={25} />
          </button>
        </div>
      </div>

      <div className="iframe-container w-full flex-grow">
        <iframe
          src="https://cdao-tw-bot-demo-xp3wk5dr5q-ue.a.run.app"
          sandbox="allow-scripts allow-same-origin"
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;